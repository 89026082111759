var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"eng-report-details"},[(_vm.loading)?[_c('AssessmentResultLoading')]:(_vm.data_error)?[_c('app-resourse-not-found',{attrs:{"title":_vm.data_error},on:{"refresh_process":function($event){return _vm.init()}}})]:(_vm.data && _vm.data.result)?[_c('div',{staticClass:"base-card py-4 my-4"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('GoBackHeaderPercentage',{attrs:{"title":_vm.$t('assessments.english-assessment-result'),"score":_vm.data.result.overall_score,"name":_vm.get_name(_vm.data)}})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('div',{staticClass:"\n              d-flex\n              align-start align-md-center\n              flex-column flex-md-row flex-wrap\n            ",staticStyle:{"gap":"0.6rem"}},[_c('div',{staticClass:"d-flex align-center info-card",class:{
                rtl: _vm.get_site_direction === _vm.SiteDirections.RTL
              }},[_c('img',{attrs:{"width":"20","height":"20","src":require("@/assets/icons/linear/clock.svg")}}),_c('p',{staticClass:"mb-0 mx-1"},[_vm._v(" "+_vm._s(_vm.convert_seconds_to_minutes(_vm.data.assessment.allowed_time))+" "+_vm._s(_vm.$t("shared.minutes"))+" ")])]),(_vm.get_questions_count())?_c('div',{staticClass:"d-flex align-center info-card",class:{
                rtl: _vm.get_site_direction === _vm.SiteDirections.RTL
              }},[_c('img',{attrs:{"width":"20","height":"20","src":require("@/assets/icons/assessments/icon-message-question.svg")}}),_c('p',{staticClass:"mb-0 mx-1",domProps:{"textContent":_vm._s(_vm.get_questions_count())}})]):_vm._e(),(
                _vm.get_questions_count(_vm.AppAssessments.QuestionType.LONG_TEXT)
              )?_c('div',{staticClass:"d-flex align-center info-card",class:{
                rtl: _vm.get_site_direction === _vm.SiteDirections.RTL
              }},[_c('img',{attrs:{"width":"20","height":"20","src":require("@/assets/icons/assessments/icon-message-question.svg")}}),_c('p',{staticClass:"mb-0 mx-1",domProps:{"textContent":_vm._s(
                  _vm.get_questions_count(_vm.AppAssessments.QuestionType.LONG_TEXT)
                )}})]):_vm._e()])]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"\n              d-flex\n              align-start align-sm-center\n              justify-sm-end\n              flex-column flex-sm-row\n            "},[_c('v-btn',{staticClass:"base-body-text text-capitalize info-btn pr-sm-12",class:{
                rtl: _vm.get_site_direction === _vm.SiteDirections.RTL
              },attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.open_details_dialog()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-file-document-outline")]),_vm._v(" "+_vm._s(_vm.$t("shared.view-details"))+" ")],1),_c('v-btn',{staticClass:"base-body-text text-capitalize ps-sm-12",attrs:{"text":"","loading":_vm.download_report_loading,"color":"primary"},on:{"click":_vm.download_report}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-file-download-outline")]),_vm._v(" "+_vm._s(_vm.$t("assessments.download-report"))+" ")],1)],1)])],1)],1),(_vm.speaking_assessment_details.length > 0)?_c('div',[_c('GoBackHeaderPercentage',{staticClass:"mt-8",attrs:{"showBackButton":false,"title":_vm.get_translation('speaking'),"score":_vm.data.result.english_speaking_score,"showPassAndFail":false}}),_c('div',[[_c('p',{staticClass:"mb-0 base-section-title"},[_vm._v(" "+_vm._s(_vm.get_translation("individual-questions-result"))+" ")]),_c('div',{staticClass:"base-card my-8"},[_c('v-row',[(_vm.speaking_assessment_details.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_vm._l((_vm.speaking_assessment_details),function(item,index){return _c('v-chip',{key:index,staticClass:"mb-6 mx-2",class:{
                    'chip-border': !item.selected
                  },attrs:{"color":item.selected ? 'dark-darker' : ''},on:{"click":function($event){return _vm.select_speaking_question(index)}}},[_c('span',{staticClass:"base-section-sub-title px-3",class:item.selected ? 'white--text' : ''},[_vm._v(" "+_vm._s(_vm.$t("shared.question"))+" #"+_vm._s(index + 1)+" ")])])}),_c('div',[_c('p',{staticClass:"mb-0 base-body-text",staticStyle:{"direction":"ltr"},domProps:{"innerHTML":_vm._s(
                      _vm.speaking_assessment_details[_vm.selected_index].question
                        .question_text
                    )}})]),(_vm.check_assessment_result(_vm.selected_index))?_c('div',{staticClass:"mt-4"},[_c('AiEvaluationCard',{attrs:{"ai_evaluation":_vm.speaking_assessment_details[_vm.selected_index]
                        .assessment_result}})],1):_c('div',{staticClass:"mt-4"},[_c('div',[_c('p',{staticClass:"\n                        base-section-sub-title\n                        font-weight-bold\n                        mb-0\n                        px-2\n                      "},[_vm._v(" AI Evaluation ")]),_c('p',{staticClass:"base-body-text mb-0 primary-dark--text ml-2"},[_vm._v(" This question is excluded from AI evaluation and will not contribute to the final score. ")])])])],2):_vm._e()],1)],1)]],2),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0 base-section-title"},[_vm._v(" "+_vm._s(_vm.get_translation("average-result"))+" ")])]),_vm._l((_vm.data.result.overall_result),function(value,key,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"6","md":"4"}},[(key === _vm.AppAssessments.OverallResultKeys.COHERENCE)?[_c('AssessmentScoreCard',{attrs:{"icon":require('@/assets/assesments/dialogue.svg'),"title":_vm.get_translation('fleuncy-title'),"color_hex":_vm.get_color_by_name('warning'),"color_name":"warning","value":value}})]:_vm._e(),(key === _vm.AppAssessments.OverallResultKeys.LEXICAL_RESOURCE)?[_c('AssessmentScoreCard',{attrs:{"icon":require('@/assets/assesments/light-bulb.svg'),"title":_vm.get_translation('lexical-title'),"color_hex":_vm.get_color_by_name('success'),"color_name":"success","value":value}})]:_vm._e(),(key === _vm.AppAssessments.OverallResultKeys.GRAMMAR)?[_c('AssessmentScoreCard',{attrs:{"icon":require('@/assets/assesments/font.svg'),"title":_vm.get_translation('grammer-title'),"color_hex":_vm.get_color_by_name('info'),"color_name":"info","value":value}})]:_vm._e()],2)})],2)],1):_vm._e(),_c('div',{staticClass:"mt-8"},[_c('GoBackHeaderPercentage',{staticClass:"mt-2",attrs:{"showBackButton":false,"title":_vm.get_translation('writing'),"score":_vm.data.result.english_writing_score,"showPassAndFail":false}}),(
          _vm.get_answers_by_type(_vm.AppAssessments.QuestionFilter.WRITTEN).length >
          0
        )?[_c('div',{staticClass:"base-card py-4 my-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"\n                  d-flex\n                  align-start align-md-center\n                  flex-column flex-md-row flex-md-wrap\n                ",staticStyle:{"gap":"1rem"}},[(
                    _vm.get_questions_count(
                      _vm.AppAssessments.QuestionType.HTML,
                      _vm.AppAssessments.QuestionFilter.WRITTEN
                    )
                  )?_c('div',{staticClass:"d-flex align-center info-card"},[_c('img',{attrs:{"width":"30","height":"30","src":require("@/assets/icons/assessments/icon-message-question.svg")}}),_c('p',{staticClass:"mb-0 mx-2",domProps:{"textContent":_vm._s(
                      _vm.get_questions_count(
                        _vm.AppAssessments.QuestionType.HTML,
                        _vm.AppAssessments.QuestionFilter.WRITTEN
                      )
                    )}})]):_vm._e()])])],1)],1),_vm._l((_vm.get_answers_by_type(
            _vm.AppAssessments.QuestionFilter.WRITTEN
          )),function(answer,index){return [_c('div',{key:index,staticClass:"base-card mb-8"},[_c('v-chip',{attrs:{"color":"dark-darker mb-6"}},[_c('span',{staticClass:"white--text base-section-sub-title px-3"},[_vm._v(" "+_vm._s(_vm.$t("shared.task-no"))+" "+_vm._s(index + 1)+" ")])]),(_vm.writing_assessment_details[index].assessment_result)?_c('AiEvaluationCard',{attrs:{"ai_evaluation":_vm.writing_assessment_details[index].assessment_result}}):_c('div',[_c('div',[_c('p',{staticClass:"base-section-sub-title font-weight-bold mb-0 px-2"},[_vm._v(" AI Evaluation ")]),_c('p',{staticClass:"base-body-text mb-0 grey--text ml-2"},[_vm._v(" This question is excluded from AI evaluation and will not contribute to the final score. ")])])]),_c('p',{staticClass:"base-section-sub-title font-weight-bold mt-10 mb-4"},[_vm._v(" "+_vm._s(_vm.$t("shared.candidate-response"))+" ")]),_c('div',{staticClass:"answer-wrapper"},[_c('p',{domProps:{"innerHTML":_vm._s(answer.answer)}})])],1)]})]:[_c('DataNotFound',{attrs:{"title":_vm.get_translation('incomplete-written')}})],(_vm.is_assessment_details)?[_c('AssessmentViewDetails',{attrs:{"payload":_vm.data,"title":_vm.$t('assessments.english-assessment-speaking').toString()},on:{"close":function($event){_vm.is_assessment_details = false}}})]:_vm._e()],2),_c('div',{staticClass:"mt-8"},[(
          _vm.data.result &&
          _vm.data.result.english_mcq_score &&
          _vm.data.result.english_mcq_score.Total_score
        )?_c('GoBackHeaderPercentage',{staticClass:"mt-2",attrs:{"showBackButton":false,"title":_vm.$t('assessments.mcqs'),"score":_vm.data.result.english_mcq_score.Total_score || 0,"showPassAndFail":false}}):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"mt-4",attrs:{"cols":"6"}},[(
              _vm.data.result &&
              _vm.data.result.english_mcq_score &&
              _vm.data.result.english_mcq_score.mcq_part1_score
            )?_c('AssessmentScoreCard',{attrs:{"icon":require('@/assets/assesments/font.svg'),"title":_vm.get_translation('grammer-title'),"color_hex":_vm.get_color_by_name('info'),"color_name":"info","value":_vm.data.result.english_mcq_score.mcq_part1_score,"total":10}}):_vm._e()],1),_c('v-col',{staticClass:"mt-4",attrs:{"cols":"6"}},[(
              _vm.data.result &&
              _vm.data.result.english_mcq_score &&
              _vm.data.result.english_mcq_score.mcq_part2_score
            )?_c('AssessmentScoreCard',{attrs:{"icon":require('@/assets/assesments/light-bulb.svg'),"title":_vm.get_translation('synonyms-title'),"color_hex":_vm.get_color_by_name('success'),"color_name":"success","value":_vm.data.result.english_mcq_score.mcq_part2_score,"total":4}}):_vm._e()],1)],1)],1)]:[_c('app-resourse-not-found',{attrs:{"title":_vm.$t('errors.english-assessments-not-found')},on:{"refresh_process":function($event){return _vm.$router.back()}}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }