




































import { update_assessments_scores } from "@/utils/global";
import Vue from "vue";
export default Vue.extend({
  name: "AssessmentScoreCard",
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    color_hex: {
      type: String,
      required: true
    },
    color_name: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: false,
      default: 5
    }
  },
  methods: {
    update_assessments_scores
  }
});
